import './index.css';
import { useEffect } from 'react';
import WebsiteContainer from '../src/components/Container/WebsiteContainer';
import Container from '../src/components/Container/Index';
import Intro from '../src/components/Sections/Intro/Index';
import MainSection from '../src/components/Sections/Main/Index';
import AboutSection from '../src/components/Sections/About/Index';
import ProjectsContainer from '../src/components/Sections/Projects/Index';
import ContactSection from '../src/components/Sections/Contact/Index';
import Overlay from './components/Overlay/Index';

import { onHomePageView } from './utils/googleAnalytics';

function App() {
	useEffect(() => {
		onHomePageView();
	}, [])

	return (
		<WebsiteContainer>
			<Overlay />
			<Intro />;
			<Container>
				<MainSection />;
			</Container>
			<Container id="about">
				<AboutSection />;
			</Container>
			<Container id="projects">
				<ProjectsContainer />;
			</Container>
			<Container id="contact">
				<ContactSection />;
			</Container>
		</WebsiteContainer>
	);
}

export default App;
