import { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import useScrollDirection from "../../../hooks/useScrollDirection";
import styled from "styled-components";
import { theme } from "../../../theme/theme";
import { finished } from "stream";

const Container = styled(motion.div)`
  position: fixed;
  left: 20px;
  bottom: 0;

  height: 300px;
  width: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:after {
    content: "";
    opacity: 25% !important;
    display: block;
    width: 1px;
    height: 1000px;
    margin: 0px auto;
    background-color: ${theme.colors.ui.white};
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

const SVG = styled.img`
  width: 30px;
  opacity: 50%;
  transition: 0.2s all;

  filter: invert(100%) sepia(3%) saturate(19%) hue-rotate(189deg) brightness(113%) contrast(94%);

  margin-bottom: ${theme.spacing.space.small};

  &:hover {
    cursor: pointer;
    opacity: 99%;
    transform: translateY(-5px) scale(120%);
  }
`;

export default function Social() {
  const [scrollDir] = useScrollDirection();
  const sideAnimation = useAnimation();
  const [finished, setFinished] = useState<boolean>(false);
  const [isScrollingUp, setIsScrollingUp] = useState<boolean>(true);

  const animationStates = {
    up: { y: "90px" },
    down: { y: 0 },
  };

  const animationSequence = async () => {
    await sideAnimation.start({ y: 0 });
    setFinished(true);
  };

  useEffect(() => {
    if (!finished) animationSequence();
    setIsScrollingUp(scrollDir);
  }, [scrollDir]);

  return (
    <Container
      initial={{ y: 400 }}
      animate={finished && isScrollingUp ? "up" : "down"}
      variants={animationStates}
      transition={{ delay: finished ? 0 : 5.5, duration: 2, type: "spring" }}
    >
      <a href="http://www.twitter.com/russellcareyy" target="_blank">
        <SVG src={require("../../../svg/twitter.svg").default} alt="twitter icon" />
      </a>

      <a href="https://www.linkedin.com/in/russell-carey-b199bb19a/" target="_blank">
        <SVG src={require("../../../svg/linkedin.svg").default} alt="twitter icon" />
      </a>

      <a href="https://github.com/RussellCarey" target="_blank">
        <SVG src={require("../../../svg/github.svg").default} alt="twitter icon" />
      </a>
    </Container>
  );
}
