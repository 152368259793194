import { TSpanL, TSpan } from "../shared/shared";
import { theme } from "../../../theme/theme";
import { Container, MainText, SmallText } from "./styled";
import { onTrackEvent } from "../../../utils/googleAnalytics";

export default function ContactSection() {
  const sentences = 12;

  const registerVisit = (location: string) => {
    onTrackEvent({
      category: `nav-click-${location}`,
      action: "clicked option in nav menu",
    });
  };

  return (
    <Container>
      <MainText> Time to stop scrolling. </MainText>

      {Array.apply(null, Array(sentences)).map((sen, ind) => {
        const val = 1 - (1 / 10) * ind;
        return (
          <MainText key={`${ind}${ind}`}>
            <TSpan key={ind} color={`rgba(244, 0, 118, ${val})`}>
              Time to stop scrolling.
            </TSpan>
          </MainText>
        );
      })}

      <MainText className="final-sentence">
        and{" "}
        <TSpanL
          href="mailto:rw_carey@hotmail.com"
          target="_blank"
          color={theme.colors.ui.red}
          style={{ textDecoration: "underline" }}
          onClick={() => registerVisit("contact-me")}
        >
          contact me!
        </TSpanL>
      </MainText>

      <SmallText>
        I am currently interested in new opportunities in Tokyo or remote.
      </SmallText>
    </Container>
  );
}
