import styled from "styled-components";
import { theme } from "../../../theme/theme";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${theme.colors.ui.black};
`;

export const LogoContainer = styled(motion.div)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  height: 100px;

  &:nth-child(1) {
    z-index: 4;
  }

  &:nth-child(2) {
    z-index: 3;
  }

  &:nth-child(3) {
    z-index: 2;
  }

  &:nth-child(4) {
    z-index: 1;
  }
`;

export const Logo = styled(motion.svg)`
  position: absolute;
`;

export const Letter = styled(motion.h3)`
  position: absolute;
  color: ${theme.colors.ui.red};
`;
