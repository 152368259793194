import { useEffect, useState } from "react";
import { useAnimation } from "framer-motion";
import { Logo, LogoContainer, Letter, Container } from "./styled";

export default function Index() {
  const [active, setActive] = useState<boolean>(true);

  const divAnimation = useAnimation();
  const letterAnimation = useAnimation();

  const DivSequence = async () => {
    await divAnimation.start({ scale: 0 });
    await divAnimation.start({ scale: 1 });
    await divAnimation.start({ scale: 1 });
    await divAnimation.start({ rotate: 0 });
    await divAnimation.start({ scale: 0 });

    // THis seems a bit hacky..
    setTimeout(() => {
      setActive(false);
    }, 1000);
  };

  const LetterSequence = async () => {
    await letterAnimation.start({ opacity: 1 });
    await letterAnimation.start({ scale: 1 });
    await letterAnimation.start({ scale: 0 });
  };

  useEffect(() => {
    DivSequence();
    LetterSequence();
  }, []);

  return (
    <Container
      animate={{ opacity: 0 }}
      initial={{ opacity: 1 }}
      transition={{ duration: 0.001, delay: 4 }}
      style={{ display: active ? "fixed" : "none" }}
    >
      <LogoContainer animate={divAnimation} initial={{ scale: 0, rotate: 45 }}>
        <Logo xmlns="http://www.w3.org/2000/svg" width="71" height="70" viewBox="0 0 71 70">
          <g id="Rectangle_1" data-name="Rectangle 1" fill="none" stroke="#e03076" stroke-width="4">
            <rect width="71" height="70" stroke="none" />
            <rect x="2" y="2" width="67" height="66" fill="none" />
          </g>
        </Logo>
      </LogoContainer>

      <LogoContainer animate={{ scale: 100 }} initial={{ scale: 0 }} transition={{ delay: 2.9, duration: 1 }}>
        <Logo xmlns="http://www.w3.org/2000/svg" width="71" height="70" viewBox="0 0 71 70">
          <g id="Rectangle_1" data-name="Rectangle 1" fill="none" stroke="#e03076" stroke-width="4">
            <rect width="71" height="70" stroke="none" />
            <rect x="2" y="2" width="67" height="66" fill="none" />
          </g>
        </Logo>
      </LogoContainer>

      <LogoContainer initial={{ scale: 0 }} animate={{ scale: 100 }} transition={{ delay: 3, duration: 1 }}>
        <Logo xmlns="http://www.w3.org/2000/svg" width="71" height="70" viewBox="0 0 71 70">
          <g id="Rectangle_1" data-name="Rectangle 1" fill="none" stroke="#ef853f" stroke-width="4">
            <rect width="71" height="70" stroke="none" />
            <rect x="2" y="2" width="67" height="66" fill="none" />
          </g>
        </Logo>
      </LogoContainer>

      <LogoContainer initial={{ scale: 0 }} animate={{ scale: 100 }} transition={{ delay: 3.1, duration: 1 }}>
        <Logo xmlns="http://www.w3.org/2000/svg" width="71" height="70" viewBox="0 0 71 70">
          <g id="Rectangle_1" data-name="Rectangle 1" fill="none" stroke="#6ccccf" stroke-width="4">
            <rect width="71" height="70" stroke="none" />
            <rect x="2" y="2" width="67" height="66" fill="none" />
          </g>
        </Logo>
      </LogoContainer>

      <LogoContainer initial={{ scale: 0 }} animate={{ scale: 100 }} transition={{ delay: 3.2, duration: 1 }}>
        <Logo xmlns="http://www.w3.org/2000/svg" width="71" height="70" viewBox="0 0 71 70">
          <g id="Rectangle_1" data-name="Rectangle 1" fill="none" stroke="#7e6be6" stroke-width="4">
            <rect width="71" height="70" stroke="none" />
            <rect x="2" y="2" width="67" height="66" fill="none" />
          </g>
        </Logo>
      </LogoContainer>
      <Letter initial={{ opacity: 0, scale: 1 }} animate={letterAnimation} transition={{ delay: 0.5, duration: 0.55 }}>
        R
      </Letter>
    </Container>
  );
}
