import { useEffect, useState, useRef } from 'react';

const useScrollDirection = () => {
  const [scrollDir, setScrollDir] = useState<boolean>(false);
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);
  const canCheck = useRef<boolean>(true);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }

      setScrollDir(scrollY > lastScrollY ? false : true);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      canCheck.current = false;

      if (!ticking) {
        setHasScrolled(true);
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;

        setTimeout(() => {
          canCheck.current = true;
        }, 200);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  return [scrollDir, hasScrolled];
};

export default useScrollDirection;
