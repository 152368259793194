export const spacing = {
  space: {
    xxsmall: "0.64rem",
    xsmall: "1.563rem",
    small: "2.442rem",
    medium: "3.052rem",
    large: "3.815rem",
    xlarge: "4.768rem",
    xxlarge: "5.96rem",
  },
};
