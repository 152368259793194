import { FunctionComponent } from "react"
import styled from "styled-components"
import { theme } from "../../../theme/theme"

const Container = styled.div`
	z-index: 10;
	position: fixed;
	top: 0;
	left: 0;

	width: 100vw;
	height: 100vh;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: rgba(0, 0, 0, 0.8);
`

const Window = styled.div`
	width: 80vw;
	height: 80vh;
`

const Frame = styled.iframe`
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
`

const CloseButton = styled.h6`
	color: ${theme.colors.ui.white};

	position: absolute;
	top: 20px;
	right: 20px;

	&:hover {
		cursor: pointer;
		transform: scale(105%);
	}
`

interface IWindowProps {
	url: string
	setShowYoutube: React.Dispatch<React.SetStateAction<boolean>>
	youtubeURL: string
}

const YoutubeWindow: FunctionComponent<IWindowProps> = ({ url, setShowYoutube, youtubeURL }) => {
	return (
		<Container>
			<CloseButton onClick={() => setShowYoutube(false)}>X</CloseButton>
			<Window>
				<Frame
					src={`https://www.youtube.com/embed/${youtubeURL}`}
					title="YouTube video player"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				></Frame>
			</Window>
		</Container>
	)
}

export default YoutubeWindow
