export const colors = {
  ui: {
    red: "#F40076",
    green: "#3ECED0",
    orange: "#FF7E26",
    purple: "#826AED",
    black: "#14161A",
    white: "#F7F7F7",
    blue: "#2274A5",
  },
  filter: {
    red: "invert(24%) sepia(95%) saturate(7471%) hue-rotate(323deg) brightness(93%) contrast(106%)",
    green: "invert(87%) sepia(90%) saturate(889%) hue-rotate(142deg) brightness(81%) contrast(100%)",
    orange: "invert(59%) sepia(50%) saturate(3146%) hue-rotate(342deg) brightness(103%) contrast(101%)",
    purple: "invert(45%) sepia(19%) saturate(2214%) hue-rotate(213deg) brightness(99%) contrast(88%);",
    blue: "invert(33%) sepia(91%) saturate(415%) hue-rotate(158deg) brightness(100%) contrast(93%);",
  },
};

export const colorsArray = [
  colors.ui.red,
  colors.ui.green,
  colors.ui.orange,
  colors.ui.purple,
  colors.ui.blue,
  colors.ui.red,
  colors.ui.green,
  colors.ui.orange,
  colors.ui.purple,
  colors.ui.blue,
  colors.ui.red,
  colors.ui.green,
  colors.ui.orange,
  colors.ui.purple,
  colors.ui.blue,
  colors.ui.red,
  colors.ui.green,
  colors.ui.orange,
  colors.ui.purple,
  colors.ui.blue,
  colors.ui.red,
  colors.ui.green,
  colors.ui.orange,
  colors.ui.purple,
  colors.ui.blue,
  colors.ui.red,
  colors.ui.green,
  colors.ui.orange,
  colors.ui.purple,
  colors.ui.blue,
  colors.ui.red,
  colors.ui.green,
  colors.ui.orange,
  colors.ui.purple,
  colors.ui.blue,
];
