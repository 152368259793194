import { FunctionComponent } from 'react';
import { theme } from '../../theme/theme';
import styled from 'styled-components';

const SectionContainer = styled.main`
	width: 100%;
	max-width: 1600px;
	height: max-content;

	margin-top: -70px;
	padding: 50px 20%;

	display: flex;
	flex-direction: column;

	background-color: ${theme.colors.ui.black};

	margin-bottom: 20%;

	@media (max-width: 1200px) {
		padding: 50px 20%;
	}

	@media (min-width: 1700px) {
		padding: 50px 10%;
	}

	@media (max-width: 500px) {
		padding: 50px 10%;
	}
`;

interface IContainerProps {
	children: React.ReactNode;
	id?: string;
}

const Container: FunctionComponent<IContainerProps> = ({ children, id }) => {
	return <SectionContainer id={id}>{children}</SectionContainer>;
};

export default Container;
