import { useState, useEffect } from 'react';
import { useAnimation } from 'framer-motion';
import { Container, SVG, SVGContainer, LinkContainer, Link } from './styled';

import useScrollDirection from '../../../hooks/useScrollDirection';
import { onTrackEvent } from '../../../utils/googleAnalytics';

export default function NavBar() {
	const [scrollDir, hasScrolled] = useScrollDirection();
	const navAnimation = useAnimation();
	const [scrollDirection, setScrollDirection] = useState<boolean>(false);
	const [isFinished, setIsFinished] = useState<boolean>(false);

	const animationStates = {
		up: { y: 0 },
		down: { y: hasScrolled ? -200 : 0 },
	};

	const animationSequence = async () => {
		await navAnimation.start({ y: 0 });
		setTimeout(() => {
			setIsFinished(true);
		}, 7500);
	};

	const registerVisit = (location: string) => {
		onTrackEvent({
			category: `nav-click-${location}`,
			action: "clicked option in nav menu"
		});
	}

	useEffect(() => {
		if (!isFinished) animationSequence();
		setScrollDirection(scrollDir);
	}, [scrollDir]);

	return (
		<Container
			initial={{ y: -200 }}
			animate={isFinished && scrollDirection ? 'up' : 'down'}
			variants={animationStates}
			transition={{ delay: isFinished ? 0 : 6.5, duration: 1, type: 'spring' }}
		>
			<SVGContainer>
				<a href="http://www.twitter.com/russellcareyy" target="_blank" onClick={() => registerVisit('twitter')} rel="noreferrer">
					<SVG src={require('../../../svg/twitter.svg').default} alt="twitter icon" />
				</a>

				<a href="https://www.linkedin.com/in/russell-carey-b199bb19a/" target="_blank" onClick={() => registerVisit('linkedin')} rel="noreferrer">
					<SVG src={require('../../../svg/linkedin.svg').default} alt="twitter icon" />
				</a>

				<a href="https://github.com/RussellCarey" target="_blank" onClick={() => registerVisit('github')} rel="noreferrer">
					<SVG src={require('../../../svg/github.svg').default} alt="twitter icon" />
				</a>
			</SVGContainer>

			<LinkContainer>
				<Link onClick={() => registerVisit('about')} href="#about">About</Link>
				<Link onClick={() => registerVisit('projects')} href="#projects">Projects</Link>
				<Link onClick={() => registerVisit('contact')} href="#contact">Contact</Link>
				<Link onClick={() => registerVisit('resume')} href="https://www.linkedin.com/in/russellcareyy/" target="_blank">
					Resume
				</Link>
			</LinkContainer>
		</Container>
	);
}
