import React, { FunctionComponent, useEffect, useState, createRef } from 'react';
import { Container, Title, TechTextContainer, TechText, Text, LinkText, LinkContainer, ProjectImage } from './styled';
import { colorsArray } from '../../../theme/colors';
import { TSpan, TSpanL } from '../shared/shared';

import useIsMobile from '../../../hooks/useIsMobile';

import { onTrackEvent } from '../../../utils/googleAnalytics';

interface ISingleProject {
	proj: any;
	setImageString: React.Dispatch<React.SetStateAction<string>>;
	clickYoutube: Function;
}

const SingleProject: FunctionComponent<ISingleProject> = ({ proj, setImageString, clickYoutube }) => {
	const ref = createRef<HTMLDivElement>();
	const [isMobile, isPortraint] = useIsMobile();
	const [techs, setTechs] = useState<string[]>(['']);

	const data = {
		title: proj.data.title[0].text,
		tech: proj.data.tech[0].text,
		description: proj.data.description,
		image: proj.data.image.url,
		youtube: proj.data.youtube,
		github: proj.data.github.url,
		designs: proj.data.designs.url,
		live: proj.data.live.url,
	};

	useEffect(() => {
		setTechs(data.tech.split(' '));
	}, [data.tech]);

	const registerTrack = (type: string) => {
		const projectName = data.title.slice(0, 8)
		onTrackEvent({
			category: `proj-${type}-${projectName}`,
			action: "clicked option in proj"
		});
	}

	return (
		<Container onMouseOver={() => setImageString(data.image)} ref={ref}>
			{isMobile ? <ProjectImage src={data.image} /> : null}
			<Title>{data.title}</Title>

			<TechTextContainer>
				{techs
					? techs.map((item, ind) => {
							return (
								<TechText key={item}>
									<TSpan key={`${item}-span`} color={colorsArray[ind]}>
										{item}
									</TSpan>
								</TechText>
							);
					  })
					: null}
			</TechTextContainer>

			{data.description!.map((desc: any) => {
				return <Text key={desc.text.slice(5)}>{desc.text}</Text>;
			})}

			<LinkContainer>
				{data.youtube[0] && (
					<LinkText>
						<TSpanL color={colorsArray[0]} onClick={() => {clickYoutube(data.youtube[0].text); registerTrack("yt-btn")}}>
							View Youtube
						</TSpanL>
					</LinkText>
				)}

				{data.github && (
					<LinkText>
						<TSpanL color={colorsArray[1]} href={data.github} target="_blank" onClick={() =>registerTrack("gh-btn")}>
							View GitHub
						</TSpanL>
					</LinkText>
				)}

				{/* <LinkText>
					{data.live && (
						<LinkText>
							<TSpanL color={colorsArray[2]} href={data.live} target="_blank" onClick={() =>registerTrack("live-btn")}>
								View Live Site
							</TSpanL>
						</LinkText>
					)}
				</LinkText> */}
				{/* <LinkText>
					{data.designs && (
						<LinkText>
							<TSpanL color={colorsArray[3]} href={data.designs} target="_blank" onClick={() =>registerTrack("design-btn")}>
								View Design File
							</TSpanL>
						</LinkText>
					)}
				</LinkText> */}
			</LinkContainer>
		</Container>
	);
};

export default SingleProject;
