import { useEffect, useState } from "react";
import { theme } from "../../../theme/theme";
import { TSpan } from "../shared/shared";
import { Container, Title } from "./styled";

export default function MainSection() {
  const [time, setTime] = useState<string>("Hello there");

  useEffect(() => {
    const current = new Date(
      new Date().setHours(new Date().getHours() - new Date().getTimezoneOffset() / 60)
    ).toISOString();

    const currentNew = +current.slice(11, 13);
    if (currentNew < 12) return setTime("Good morning");
    if (currentNew > 12 && currentNew < 18) return setTime("Good afternoon");
    if (currentNew > 18) return setTime("Good evening");
  }, []);

  return (
    <Container>
      <Title
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 4.5, duration: 3, type: "spring" }}
      >
        {time}, I am <TSpan color={theme.colors.ui.green}>Russell</TSpan> and I build
        <TSpan color={theme.colors.ui.purple}> web based </TSpan>
        <TSpan color={theme.colors.ui.orange}> projects </TSpan>
        and
        <TSpan color={theme.colors.ui.red}> experiences. </TSpan>
      </Title>
    </Container>
  );
}
