import ReactGA from "react-ga4"
export const TRACKING_ID = "G-ZGSKW92JTB";
ReactGA.initialize(TRACKING_ID);


export const onHomePageView = () => {
  ReactGA.send({ hitType: "pageview", page: "/", title: "Homepage view" });
};

// {
//   category: "your category",
//   action: "your action",
//   label: "your label", // optional
//   value: 99, // optional, must be a number
//   nonInteraction: true, // optional, true/false
//   transport: "xhr", // optional, beacon/xhr/image
// }

export const onTrackEvent = (event: any) => {
  ReactGA.event(event);
};