import styled from 'styled-components';
import { theme } from '../../../theme/theme';
import { motion } from 'framer-motion';

export const Container = styled.div`
	width: 100%;
	height: max-content;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	margin-bottom: 70px;
`;

export const TitleContainer = styled.div`
	width: 100%;
`;

export const Title = styled.h1`
	margin-bottom: ${theme.spacing.space.small};

	&:first-child {
		color: ${theme.colors.ui.red};
	}

	&:nth-child(2) {
		color: ${theme.colors.ui.orange};
	}
`;

export const AboutContainer = styled.div`
	width: 100%;
	display: flex;

	@media (max-width: 800px) {
		flex-direction: column;
	}
`;

export const TextContainer = styled.div`
	position: relative;
	width: 70%;
	display: flex;
	flex-direction: column;

	padding-right: ${theme.spacing.space.xlarge};

	@media (max-width: 800px) {
		width: 100%;
		padding-right: 0;
	}
`;

export const TextBox = styled(motion.div)`
	flex: 1;
	height: max-content;

	@media (max-width: 500px) {
		width: 100%;
	}
`;

export const Text = styled.p`
	color: ${theme.colors.ui.white};
	margin-bottom: ${theme.spacing.space.medium};

	@media (max-width: 1200px) {
		font-size: 1.6rem;
		line-height: 3.5rem;
	}

	@media (max-width: 500px) {
		line-height: 3rem;
	}

	& u {
		cursor: pointer;
	}
`;

export const ImageContainer = styled.div`
	position: relative;
	max-width: 400px;
	width: 50%;
	height: 50%;

	border-radius: 8px;

	@media (max-width: 800px) {
		width: calc(100% - 30px);
	}
`;

export const Image = styled.img`
	position: relative;
	z-index: 2 !important;
	border-radius: 8px;
	width: 100%;
	height: 100%;
`;

export const ImageCover = styled.div`
	position: absolute;
	top: 0;
	left: 0;

	background-color: ${theme.colors.ui.red};
	opacity: 0.3;
	z-index: 3;
	width: 100%;
	height: 100%;
	border-radius: 8px;

	transition: all 0.4s;

	&:hover {
		opacity: 0;
	}
`;

interface IProps {
	offset: string;
	color: string;
}
export const ImageShadow = styled.div`
	position: absolute;

	top: ${(props: IProps) => props.offset};
	left: ${(props: IProps) => props.offset};
	width: 100%;
	height: 100%;

	border-radius: 8px;
	border: solid 3px ${(props: IProps) => props.color};
`;
