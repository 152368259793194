import { useEffect, useState } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { theme } from "../../../theme/theme";
import useScrollDirection from "../../../hooks/useScrollDirection";

const Container = styled(motion.div)`
  position: fixed;
  right: 20px;
  bottom: 0;

  height: 320px;
  width: 100px;

  opacity: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:after {
    content: "";
    opacity: 50%;
    display: block;
    width: 1px;
    height: 1000px;
    margin: 0px auto;
    background-color: ${theme.colors.ui.white};
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

const SideText = styled.p`
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: ${theme.colors.ui.white};
  margin-bottom: ${theme.spacing.space.small};
  letter-spacing: 1px;
  font-size: 1.4rem;

  transition: 0.3s ease-in;
`;

const Link = styled.a`
  color: ${theme.colors.ui.white};
  text-decoration: none;

  transition: all 0.3s ease-in;

  &:hover {
    color: ${theme.colors.ui.green};
    opacity: 100% !important;
    cursor: pointer;
  }
`;

export default function Social() {
  const [scrollDir] = useScrollDirection();
  const sideAnimation = useAnimation();
  const [finished, setFinished] = useState<boolean>(false);
  const [isScrollingUp, setIsScrollingUp] = useState<boolean>(true);

  const animationStates = {
    up: { y: "100px" },
    down: { y: 0 },
  };

  const animationSequence = async () => {
    await sideAnimation.start({ y: 0 });
    setFinished(true);
  };

  useEffect(() => {
    if (!finished) animationSequence();
    setIsScrollingUp(scrollDir);
  }, [scrollDir]);

  return (
    <Container
      initial={{ y: 400 }}
      animate={finished && isScrollingUp ? "up" : "down"}
      variants={animationStates}
      transition={{ delay: finished ? 0 : 6, duration: 2, type: "spring" }}
    >
      <SideText>
        <Link href="mailto:rw_carey@hotmail.com">rw_carey@hotmail.comk</Link>
      </SideText>
    </Container>
  );
}
