import { useEffect, useState, createRef, useRef } from "react";
import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";

import prismicClient from "../../../utils/prismic";

import SingleProject from "./SingleProject";
import YoutubeWindow from "./YoutubeWindow";

import useIsMobile from "../../../hooks/useIsMobile";

const fade = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Placeholder = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 400px;
  height: 250px;
  background-color: #1f1f1f;
  animation: ${fade} 2s linear infinite;
  border-radius: 10px;
`;

const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Image = styled(motion.img)`
  position: absolute;
  z-index: 0;
  width: 80%;
  opacity: 80%;
  border-radius: 10px;
`;

export default function Index() {
  const ref = createRef<HTMLDivElement>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isMobile, isPortrait] = useIsMobile();
  const [showYoutube, setShowYoutube] = useState<boolean>(false);
  const [youtubeURL, setYoutubeURL] = useState<string>("");
  const [imageString, setImageString] = useState<string>("");
  const [projects, setProjects] = useState<Array<any>>();
  const [mousePosition, setMousePosition] = useState<any>({});
  const timeoutRef = useRef<boolean>(true);

  const handleMouseMove = (e: React.MouseEvent) => {
    if (timeoutRef.current) {
      timeoutRef.current = false;

      const rect = ref.current!.getBoundingClientRect();
      const mousePos = {
        x: e.clientX - rect.left + rect.width / 4,
        y: e.clientY - rect.top - rect.width / 4,
      };
      setMousePosition(mousePos);

      setTimeout(() => {
        timeoutRef.current = true;
      }, 100);
    }
  };

  const getData = async () => {
    const prismicDocs = await prismicClient.getAllByTag("projects");
    setProjects(prismicDocs.sort((a, b) => a.data.pos - b.data.pos));
  };

  const clickYoutube = (url: string) => {
    setShowYoutube(false);
    setYoutubeURL(url);
    setShowYoutube(true);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container ref={ref} onMouseOver={(e) => handleMouseMove(e)}>
      {/* Show in-built youtube player. */}
      {showYoutube ? (
        <YoutubeWindow
          url={""}
          setShowYoutube={setShowYoutube}
          youtubeURL={youtubeURL}
        />
      ) : null}

      {projects
        ? projects.map((proj) => {
            return (
              <SingleProject
                key={proj.data.title[0].text}
                proj={proj}
                clickYoutube={clickYoutube}
                setImageString={setImageString}
              />
            );
          })
        : null}
      {imageString && !isMobile ? (
		<>
        <Image
          src={imageString}
          onLoad={() => setIsLoaded(true)}
          animate={{
            x: mousePosition.x,
            y: mousePosition.y,
          }}
          transition={{ type: "spring", stiffness: 30 }}
        />
		{!isLoaded ? ( // only show the placeholder if the image has not loaded
		<Placeholder
		  animate={{
			x: mousePosition.x,
			y: mousePosition.y,
		  }}
		  transition={{ type: "spring", stiffness: 30 }}
		/>
	  ) : null}
	  </>
      ) : (
        null
      )}
    </Container>
  );
}
