import { createRef } from "react";
import useIsVisible from "../../../hooks/useIsVisible";
import { theme } from "../../../theme/theme";
import { TSpan, TSpanTL } from "../shared/shared";
import * as Styled from "./styled";

export default function Index() {
  const targetRef = createRef<HTMLDivElement>();
  const isVisible = useIsVisible(
    { root: null, rootMargin: "0px", threshold: 0.9 },
    targetRef,
    false
  );

  return (
    <Styled.Container ref={targetRef}>
      {isVisible ? (
        <>
          <Styled.TitleContainer>
            <Styled.Title>A little about me.</Styled.Title>
          </Styled.TitleContainer>

          <Styled.AboutContainer>
            <Styled.TextContainer>
              <Styled.TextBox>
                <Styled.Text>
                  Hello! My name is Russell, a SE currently
                  working for Japan's number one portfolio site, foriio, where I
                  am responsible for the main portfolio site, which includes:
                  design work, some back end using RoR, feature development and
                  of course, front end.
                </Styled.Text>
                <Styled.Text>
                  My interest in programming started back in 2019 when I decided
                  to build a{" "}
                  <TSpanTL
                    color={theme.colors.ui.orange}
                    href="https://github.com/RussellCarey/Unity-Music-Game"
                    target="_blank"
                  >
                    music rhythm game using Unity
                  </TSpanTL>
                  . Shortly after I discovered a love for web development and
                  never looked back.
                </Styled.Text>
                <Styled.Text>
                  Fast-forward to today, I spend nearly everyday building, studying
                  and improving my skills. I am currently completeing a BSc in
                  Computer Science and also I am currently working on my own applications and mini SaSS products.
                </Styled.Text>

                <Styled.Text>
                  I am currently working with but not limited to:{" "}
                  <TSpan color={theme.colors.ui.red}>React</TSpan>,{" "}
                  <TSpan color={theme.colors.ui.green}>Vue</TSpan>,{" "}
                  <TSpan color={theme.colors.ui.orange}>Typescript</TSpan>,{" "}
                  <TSpan color={theme.colors.ui.green}>JavaScript</TSpan>,{" "}
                  <TSpan color={theme.colors.ui.purple}>PostgreSQL</TSpan>,{" "}
                  <TSpan color={theme.colors.ui.blue}>Jest</TSpan>,{" "}
                  <TSpan color={theme.colors.ui.red}>Docker</TSpan>,{" "}
                  <TSpan color={theme.colors.ui.orange}>Git</TSpan>{" "}
                  <TSpan color={theme.colors.ui.purple}>
                    Ruby / Ruby on Rails
                  </TSpan>
                  , <TSpan color={theme.colors.ui.red}>Node / Express</TSpan>.
                </Styled.Text>
              </Styled.TextBox>
            </Styled.TextContainer>
            <Styled.ImageContainer>
              <Styled.ImageCover />
              <Styled.Image src="/imgs/me.jpeg" />
              <Styled.ImageShadow
                offset={`${15}px`}
                color={theme.colors.ui.red}
              />
              ;
              <Styled.ImageShadow
                offset={`${30}px`}
                color={theme.colors.ui.green}
              />
              ;
              <Styled.ImageShadow
                offset={`${45}px`}
                color={theme.colors.ui.blue}
              />
              ;
              <Styled.ImageShadow
                offset={`${60}px`}
                color={theme.colors.ui.orange}
              />
              ;
            </Styled.ImageContainer>
          </Styled.AboutContainer>
        </>
      ) : null}
    </Styled.Container>
  );
}
