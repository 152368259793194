import styled from 'styled-components';
import { theme } from '../../../theme/theme';
import { motion } from 'framer-motion';

interface ITextSpanProps {
	color: string;
}

export const TSpan = styled(motion.span)`
	color: ${(props: ITextSpanProps) => props.color};
`;

export const TSpanB = styled.span`
	font-weight: bold;
	color: ${(props: ITextSpanProps) => props.color};
`;

export const TSpanL = styled.a`
	cursor: pointer;
	text-decoration: none;
	color: ${(props: ITextSpanProps) => props.color};
	transition: text-decoration 1s;

	&:not(:last-child) {
		margin-right: ${theme.spacing.space.medium};
	}

	&:hover {
		text-decoration: underline;
	}
`;

export const TSpanTL = styled.a`
	cursor: pointer;
	text-decoration: underline;
	color: ${(props: ITextSpanProps) => props.color};
	transition: text-decoration 1s;

	&:hover {
		text-decoration: underline;
	}
`;
