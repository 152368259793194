import styled from 'styled-components';
import { theme } from '../../../theme/theme';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
	position: relative;
	width: 100%;

	display: flex;
	flex-direction: column;

	& > .final-sentence {
		margin-top: ${theme.spacing.space.xxlarge};
	}
`;

export const MainText = styled.h1`
	color: ${theme.colors.ui.white};
	margin-bottom: ${theme.spacing.space.xxsmall};
`;

export const SmallText = styled(motion.p)`
	color: ${theme.colors.ui.white};

	@media (max-width: 600px) {
		text-align: center;
	}
`;
