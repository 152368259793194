import { useEffect, RefObject } from "react";
import { useState, useMemo } from "react";

const useElementIsVisible = (options: Object, targetRef: RefObject<HTMLDivElement>, turnoff: boolean) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const callBack = (entries: any) => {
    const [entry] = entries;

    if (entry.isIntersecting) {
      setIsVisible(entry.isIntersecting);
    } else {
      if (turnoff) setIsVisible(false);
    }
  };

  const optionsMemo = useMemo(() => {
    return options;
  }, [options]);

  useEffect(() => {
    const observer = new IntersectionObserver(callBack, optionsMemo);
    const currentTarget = targetRef.current;

    if (currentTarget) observer.observe(currentTarget);

    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
  }, [targetRef]);

  return isVisible;
};

export default useElementIsVisible;
