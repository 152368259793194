import styled from 'styled-components';
import { theme } from '../../../theme/theme';
import { motion } from 'framer-motion';

export const Container = styled(motion.nav)`
	z-index: 20;
	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	height: 70px;
	padding: 0 50px;

	background-image: linear-gradient(rgba(0, 0, 0, 0.5), transparent);

	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const SVGContainer = styled.div`
	display: flex;
	@media (min-width: 500px) {
		padding: 0 20px;
		opacity: 0;
	}
`;

export const SVG = styled.img`
  width: 25px;
  opacity: 50%;
  transition: 0.2s all;

  margin-right: ${theme.spacing.space.xsmall};
  filter: invert(100%) sepia(3%) saturate(19%) hue-rotate(189deg) brightness(113%) contrast(94%);

  &:active:hover {
    filter ${theme.colors.filter.red};
  }
`;

export const LinkContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const Link = styled.a`
	font-size: 1.6rem;

	font-weight: 500;
	text-decoration: none;
	cursor: pointer;

	&:not(:last-child) {
		margin-right: ${theme.spacing.space.large};
	}

	&:nth-child(1) {
		color: ${theme.colors.ui.red};
	}

	&:nth-child(2) {
		color: ${theme.colors.ui.orange};
	}

	&:nth-child(3) {
		color: ${theme.colors.ui.purple};
	}

	&:nth-child(4) {
		color: ${theme.colors.ui.green};
	}

	@media (max-width: 500px) {
		opacity: 0;
	}
`;
