import styled from 'styled-components';
import { motion } from 'framer-motion';
import { theme } from '../../../theme/theme';

export const Container = styled.div`
	width: 100%;
	height: 100vh;

	display: flex;
	align-items: center;
`;

export const Title = styled(motion.h1)`
	position: relative;
	z-index: 2;
	width: 95%;
	color: ${theme.colors.ui.white};

	@media (max-width: 800px) {
		width: 80%;
		font-size: 5rem;
		line-height: 6rem;
	}

	@media (max-width: 500px) {
		text-align: center;
		width: 95%;
	}

	@media (max-width: 400px) {
		font-size: 4rem;
		line-height: 4rem;
	}
`;
