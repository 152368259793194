import styled from 'styled-components';
import { theme } from '../../../theme/theme';

export const Container = styled.div`
	z-index: 5;
	display: relative;

	width: 100%;
	padding: 50px 0;

	display: flex;
	flex-direction: column;

	@media (max-width: 800px) {
		width: 100%;
	}
`;

export const SectionTitle = styled.h1`
	color: ${theme.colors.ui.red};
	margin-bottom: ${theme.spacing.space.xxlarge};
`;

export const Title = styled.h1`
	text-align: left;
	color: ${theme.colors.ui.white};
	margin-bottom: ${theme.spacing.space.xsmall};
`;

export const TechTextContainer = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: ${theme.spacing.space.xsmall};
`;

export const TechText = styled.p`
	&:not(:last-child) {
		margin-right: ${theme.spacing.space.xsmall};
	}
`;

export const LinkContainer = styled.div`
	display: flex;
	margin-top: ${theme.spacing.space.xsmall};

	@media (max-width: 1000px) {
		display: flex;
		flex-direction: column;
	}
`;

export const LinkText = styled.p`
	margin-right: ${theme.spacing.space.small};

	@media (max-width: 1000px) {
		margin-bottom: 0;
	}
`;

export const Text = styled.p`
	text-align: left;
	color: ${theme.colors.ui.white};
`;

export const ProjectImage = styled.img`
	width: 100%;
	margin-bottom: ${theme.spacing.space.small};
`;
