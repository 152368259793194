import { useEffect } from "react"
import { useState } from "react"

const useIsMobile = () => {
	const [isMobile, setIsMobile] = useState<boolean>(false)

	useEffect(() => {
		const devices = ["Android", "android", "webOS", "webos", "iphone", "iPhone", "iPad", "ipad", "ipod", "Ipod"]

		devices.forEach((dev) => {
			if (navigator.userAgent.indexOf(dev) !== -1) {
				setIsMobile(true)
			}
		})

		// console.log(/Android|webOS|iPhone|iPad|iPod|/i.test(navigator.userAgent));
		// if (/Android|webOS|iPhone|iPad|iPod|/i.test(navigator.userAgent)) {
		//   setIsMobile(true);
		// }
	}, [])

	return [isMobile, window.matchMedia("(orientation: portrait)").matches]
}

export default useIsMobile
