import { motion } from "framer-motion";
import styled from "styled-components";
import NavBar from "./Navbar/Index";
import Social from "./Side/Social";
import Email from "./Side/Email";

const Container = styled.div`
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
`;

const BottomFade = styled(motion.div)`
  z-index: 10;
  width: 100%;
  height: 70px;

  position: fixed;
  bottom: 0;
  left: 0;

  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
`;

export default function OverLay() {
  return (
    <Container>
      <NavBar />
      <BottomFade initial={{ y: 200 }} animate={{ y: 0 }} transition={{ delay: 5.5, duration: 2, type: "spring" }} />
      <Social />
      <Email />
    </Container>
  );
}
