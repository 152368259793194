import { FunctionComponent } from "react";
import { theme } from "../../theme/theme";
import styled from "styled-components";

const WebsiteContainer = styled.main`
  width: 100vw;
  max-width: 100%;
  height: max-content;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${theme.colors.ui.black};

  overflow-y: scroll;

  padding: 0;
  margin: 0;
`;

interface IContainerProps {
  children: React.ReactNode;
}

const WebsiteCon: FunctionComponent<IContainerProps> = ({ children }) => {
  return <WebsiteContainer>{children}</WebsiteContainer>;
};

export default WebsiteCon;
